/**
 * @param {object} data
 * @param {boolean} doUpdate
 */
export default function saveField(data, doUpdate = true) {
    let actValue;
    const self = this;
    let name = data.name;

    if (data.type === 'relate' || data.type === 'parent') {
        name = data.fieldName;
    }

    const indexof = data.way.indexOf('/fields/' + name);
    const prefix = data.way.substr(0, indexof);

    let prevValue = null;

    if (this.getViewName() === 'import') {
        this.mapImportData(data);
    } else if (data.type === 'file') {
        self.dsClear();
        self.dsAdd('set', data.way + '/value', data.value.name);
        if (data.value.file.deleteFile) {
            self.dsAdd('set', prefix + '/changes/fields/' + data.name, '');
        } else {
            self.dsAdd('set', prefix + '/changes/fields/' + data.name, data.value.file.id + '::' + data.value.name);
            self.dsAdd('set', prefix + '/fields/' + data.name + '/def/file/id', data.value.file.id);
        }
        self.dsAdd('add', prefix + '/changes/files', data.value.file);
    } else if (data.type !== 'relate' && data.type !== 'parent') {
        actValue = this.dataGet(prefix+'/fields/' + data.name + '/value');
        prevValue = actValue;

        // ________________________________ ONLY FOR duration_minutes
        let minutesValue = undefined;
        if (data.type === 'hoursMinutes') {
            minutesValue = this.dataGet(prefix+'/fields/'+data.name+'/def/minutesValue');
        }
        // ________________________________ END

        self.dsClear();
        self.dsAdd('set', data.way + '/value', data.value);

        if (data.cancelEdit !== false) {
            self.dsAdd('set', data.way + '/actEdit', false);
        }

        if (actValue !== data.value || ['date', 'datetime'].includes(data.type)) {
            self.dsAdd('set', prefix + '/changes/fields/' + data.name, data.value);
            self.dsAdd('set', data.way+'/def/edit', true);
        }

        // ________________________________ ONLY FOR duration_minutes
        if (minutesValue !== data.valueMinutes) {
            const minutesField = data.minutesField ?? data.name.replace('hours', 'minutes');
            self.dsAdd('set', `${data.way}/def/minutesValue`, data.valueMinutes);
            self.dsAdd('set', `${prefix}/fields/${minutesField}/value`, data.valueMinutes);
            self.dsAdd('set', `${prefix}/changes/fields/${minutesField}`, data.valueMinutes);
        }
        // ________________________________ END

        self.dsAdd('set', prefix+'/canSave', true);
    } else {
        actValue = this.dataGet(
            prefix + '/fields/' + data.name + '/def/id_value',
        );
        if (!actValue) {
            const group = this.dataGet(prefix + '/fields/' + data.name + '/def/group');
            actValue = {
                id: this.dataGet(prefix + '/fields/' + group + '/def/id_value'),
                name: this.dataGet(prefix + '/fields/' + group + '/value'),
            };
        }

        prevValue = actValue;
        self.dsClear();
        self.dsAdd('set', data.way + '/value', data.value.name);
        self.dsAdd('set', data.way + '/def/id_value', data.value.id);

        if (data.cancelEdit !== false) {
            self.dsAdd('set', data.way + '/actEdit', false);
        }
        if (actValue.id !== data.value.id) {
            self.dsAdd('set', data.way+'/def/edit', true);
            self.dsAdd('set', prefix + '/fields/' + data.name + '/value', data.value.id);
            self.dsAdd('set', prefix + '/changes/fields/' + data.name, data.value.id);
        }
        if (data.type === 'parent') {
            self.dsAdd('set', prefix + '/changes/fields/parent_type', data.parent_type);
            self.dsAdd('set', data.way + '/def/parent_type_value', data.parent_type);
        }
        if (data.type === 'relate') {
            self.dsAdd('set', prefix + '/changes/fields/relate/' + data.fieldName, data.value.name);
        }
        self.dsAdd('set', prefix + '/canSave', true);
    }
    if (doUpdate) {
        self.dsProcess();
    }
    self.custom(
        'update',
        {field: data.name, value: data.value, prevValue: prevValue, prefix: prefix, module: self.dataGet(prefix+'/module')},
    );

    const checkDuplicity = this.dataGet(prefix+'/fields/'+data.name+'/def/checkDuplicity');
    const vname = this.dataGet(prefix+'/fields/'+data.name+'/def/vname');
    if (checkDuplicity) {
        self.custom('checkDuplicity', { // hook v deatilDefault.js
            field: data.name,
            value: data.value,
            vname: vname,
            prevValue: prevValue,
            prefix: prefix,
            def: self.dataGet(data.way + '/def'),
            module: self.dataGet(prefix + '/module'),
        });
    }
}
