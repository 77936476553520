import React, {useEffect, Suspense, useState} from 'react';
import Loader from '../../components/loader';
import PropTypes from 'prop-types';
// import Button from '../formElements/Button';
import sAction from 'sAction';

export default function ReactReportContainer({name, label, way, isDashboard}) { // eslint-disable-line
    const [ReportToRender, setReportToRender] = useState(null);

    useEffect(() => {
        const Report = React.lazy(() => import(`../../customReports/reports/${name}/${name}.js`));
        setReportToRender(Report);
        document.title = sAction.translate(label, 'acm_coripoReports');
    }, [name, label]);

    return (
        <Suspense fallback={<Loader/>}>
            {ReportToRender &&
            <div>
                <ReportToRender title={label} isDashboard={isDashboard}/>
            </div>}
        </Suspense>
    );
}

ReactReportContainer.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    way: PropTypes.string,
    isDashboard: PropTypes.bool,
};
