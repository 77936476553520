import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';

import TickBox from '../formElements/TickBox';
import CalExternalEvents from './calExternalEvents';
import Button from '../formElements/Button';

import sAction from 'sAction';
import DropdownAnimation from '../animation/DropdownAnimation';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

import {TextField} from '@mui/material';
import Loader from '../loader';

class CalFilters extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tabSelected: sAction.dataGet('conf/user/orders_planner') === '1' ? 'events' : 'filter',
            userIsOrdersPlanner: sAction.dataGet('conf/user/orders_planner'),
            searchValue: '',
            searchData: [],
            searchLoading: false,
        };
    }

    /**
     *
     * @param {string} name
     * @param {any} value
     */
    updateFilters(name, value) {
        sAction.dataSet('calendar/filters/' + name, value);
        // this.applyFilters();

        // propsani do nastaveni
        sAction.dataSet('calendar/setting/' + name, value);
        const calSettings = sAction.dataGet('calendar/setting').toJS();
        calSettings[name] = value;
        sAction.rest.post('saveCalSettings', calSettings, (returnData) => {
            if (!returnData) {
                sAction.error(sAction.translate('LBL_ERROR_SAVING', 'Calendar'));
            }

            sAction.calPlanReload();
        });
        if (name === 'unpackActivities') {
            sAction.calPlanReload();
        }
    }

    /**
     * applyFilters
     */
    applyFilters() {
        sAction.applyCalFilters();
    }

    componentDidUpdate() {
    }

    /**
     *
     * @param {string} field
     * @param {string} key
     * @param {any} val
     */
    updateStateFilters(field, key, val) {
        const pomStates = sAction.dataGet('calendar/filters/' + field).toJS();
        if (val) {
            pomStates.push(key);
        } else {
            const pomInd = pomStates.indexOf(key);
            pomStates.splice(pomInd, 1);
        }

        this.updateFilters(field, pomStates);
    }

    /**
     * getEventClass
     * @param {string} val
     * @returns {string}
     */
    getEventClass(val) {
        switch (val) {
            case 'Planned':
            case 'Not Started':
                return 'eventNotStarted';
            case 'Pending Input':
                return 'eventPending';
            case 'Held':
            case 'Completed':
                return 'eventSuccess';
            case 'Not Held':
            case 'Deferred':
                return 'eventFailed';
            default:
                return 'eventInProgress';
        }
    }

    /**
     * getEventIcon
     * @param {string} val
     * @returns {string}
     */
    getEventIcon(val) {
        switch (val) {
            case 'showMeetings':
                return <span className="icon-meetings calFilterIcon"></span>;
            case 'showCalls':
                return <span className="icon-calls calFilterIcon"></span>;
            case 'showTasks':
                return <span className="icon-tasks calFilterIcon"></span>;
            case 'showEvents':
                return <span className="icon-events calFilterIcon"></span>;
        }
    }

    /**
     * handleSearch
     * @param {any} event
     * @returns {void}
     */
    handleSearch(event) {
        const termin = event.target.value;
        this.setState({searchValue: termin});
        const sharedView = sAction.dataGet('calendar/setting/sharedView');
        let users = [];
        if (sharedView) {
            users = sAction.dataGet('calendar/setting/sharedIds');
        }
        const params = {
            termin: termin,
            users: users,
        };
        if (termin.length > 2) {
            this.setState({searchLoading: true});
            sAction.calEventsSearch(params, (data) => {
                if (data.status) {
                    this.setState({searchData: data.message.data});
                }
                this.setState({searchLoading: false});
            });
        }
    }

    /**
     *
     * @param {*} recordId
     * @param {*} module
     */
    handleEventClick = (recordId, module) => {
        sAction.closeRightPanel();
        sAction.rightPanelDetail(module, recordId);
    };

    /**
     *
     * @param {*} e
     * @param {*} date
     */
    handleTargetClick = (e, date) => {
        e.stopPropagation();
        sAction.dataSet('calendar/gotoDate', date);
    };

    /**
     * handleSearchCancel
     */
    handleSearchCancel() {
        this.setState({searchValue: '', searchData: []});
    }

    render() {
        let classPom = 'calFilters';
        let visibClass = '';

        // TODO zkusit to zase neresit pres storage, ale vzhledem k tomu jak casto se musi vse prenacitat,
        // tak budto store, nebo to budeme muset ukladat prez BE?
        const filt = localStorage.getItem('acmCalFiltersVisibility');
        if (filt === undefined || filt === 'visible') {
            classPom += ' showFilters';
        } else {
            visibClass = ' calHidden';
        }

        const meetOptions = [];
        sAction.app_strings.meeting_status_dom.forEachObject((lbl, key) => {
            meetOptions.push({
                value: key,
                label: lbl,
            });
        });

        const callOptions = [];
        sAction.app_strings.call_status_dom.forEachObject((lbl, key) => {
            callOptions.push({
                value: key,
                label: lbl,
            });
        });

        const taskOptions = [];
        sAction.app_strings.task_status_dom.forEachObject((lbl, key) => {
            taskOptions.push({
                value: key,
                label: lbl,
            });
        });

        const searchData = [];
        this.state.searchData.forEachObject((item) => {
            let statusColor = '#868f93';
            let module = item._module.toLowerCase();
            if (module.slice(-1) === 's') module = module.slice(0, -1);
            const timeline = this.props?.data?.get(module + 'View').get('timeline').toJS();
            const timelineColor = timeline?.colors?.[item.status];
            let date = '';
            if (item._module === 'Tasks') {
                date = item.date_due ? item.date_due : item.date_start;
            } else {
                date = item.date_start;
            }

            if (timelineColor) {
                statusColor = sAction.convertToPastelHSL(timelineColor);
            } else {
                statusColor = sAction.convertToPastelHSL(statusColor);
            }

            searchData.push(
                <div
                    key={item.id}
                    className={'calSearchResult calSearch' + item._module + visibClass}
                    onClick={() => this.handleEventClick(item.id, item._module)}
                >
                    <div className="calEventIconCircle" style={{'background-color': statusColor}}>
                        <div className={'calEventIcon icon-' + item._module}></div>
                    </div>
                    <div className="calSearchData">
                        <div className="calSearchResultTitle">{item.name}</div>
                        <div className="calSearchResultDate">
                            <a onClick={(e) => this.handleTargetClick(e, date)}>
                                {date && <i className='icon-target'></i>} {sAction.dateFormat(date)}
                            </a>
                        </div>
                        <div className="calSearchResultParent">
                            <a
                                href={'#detail/' + item.parent_type + '/' + item.parent_id}
                                target={'_blank'}
                                rel='noreferrer'
                                onClick={(e) => e.stopPropagation()}
                            >
                                {item.parent_name && <i className='icon-newTab'></i>} {item.parent_name}
                            </a>
                        </div>
                        <div className="calSearchResultUser">
                            <a
                                href={'#detail/Users/' + item.assigned_user_id}
                                target={'_blank'}
                                rel='noreferrer'
                                onClick={(e) => e.stopPropagation()}
                            >
                                {item._assigned_user_name && <i className='icon-users'></i>} {item._assigned_user_name}
                            </a>
                        </div>
                        <div className="calSearchResultDescription">{
                            item.description}
                        </div>
                    </div>
                </div>,
            );
        });
        if (searchData.length === 0 && this.state.searchValue.length > 2) {
            searchData.push(
                <div key={'calSearchResultNone'} className="calSearchResultNone">
                    <div className="calSearchResultTitle">{sAction.translate('LBL_NO_RESULTS_FOUND', 'Calendar')}</div>
                </div>,
            );
        }

        const filters = this.props.data.filters.toJS();

        const meetStateClass = 'filterRowState' + (!filters.showMeetings ? ' calNoDisplay' : '');
        const callStateClass = 'filterRowState' + (!filters.showCalls ? ' calNoDisplay' : '');
        const taskStateClass = 'filterRowState' + (!filters.showTasks ? ' calNoDisplay' : '');

        const pomMeetRender = [];
        meetOptions.forEachObject((opt) => {
            pomMeetRender.push(this.generateTickBox(opt, 'meet', filters));
        });

        const pomCallRender = [];
        callOptions.forEachObject((opt) => {
            pomCallRender.push(this.generateTickBox(opt, 'call', filters));
        });

        const pomTaskRender = [];
        taskOptions.forEachObject((opt) => {
            pomTaskRender.push(this.generateTickBox(opt, 'task', filters));
        });

        let openButtonClass = 'navFiltToogle icon-arrowLeft';
        if (this.props.data.filters.filtersDisplayed) {
            openButtonClass = 'navFiltToogle navToogle icon-arrowRight';
        }

        const meetFilterSection = this.buildFilterSection(
            !+filters.showMeetings ? ' notSelected' : '',
            meetStateClass,
            filters,
            pomMeetRender,
            'showMeetings',
            'LBL_SETTINGS_MEETINGS_SHOW',
        );
        const callFilterSection = this.buildFilterSection(
            !+filters.showCalls ? ' notSelected' : '',
            callStateClass,
            filters,
            pomCallRender,
            'showCalls',
            'LBL_SETTINGS_CALLS_SHOW',
        );
        const taskFilterSection = this.buildFilterSection(
            !+filters.showTasks ? ' notSelected' : '',
            taskStateClass,
            filters,
            pomTaskRender,
            'showTasks',
            'LBL_SETTINGS_TASKS_SHOW',
        );

        const filtersRender = [
            <div key={'calFiltersContainer'} className={'calFiltersContainer' + visibClass}>
                <div className={'calFiltersSection firstSection ' + visibClass}>
                    <div className={'calFiltersRow sectionRow' + (!+filters.showCompleted ? ' notSelected' : '')}>
                        <TickBox className="calFiltersTick" name="showCompleted" checked={!!filters.showCompleted}
                            onChange={(value) => this.updateFilters('showCompleted', value)}/>
                        <span className="icon-additionalActivity calFilterIcon"></span>
                        <span className="">{sAction.translate('LBL_SETTINGS_COMPLETED_SHOW', 'Calendar')}</span>
                    </div>
                </div>
                {meetFilterSection}
                {callFilterSection}
                {taskFilterSection}
            </div>,
        ];

        return (
            <div className={classPom}>

                <div className={'calSearchField' + visibClass}>
                    <TextField
                        id="calSearch"
                        label={sAction.translate('LBL_FILTER_SEARCH', 'Calendar')}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        fullWidth
                        value={this.state.searchValue}
                        onChange={(e) => this.handleSearch(e)}
                        InputProps={{
                            endAdornment: (
                                <div
                                    className="icon-detailCancel calSearchCancel"
                                    onClick={() => this.handleSearchCancel()}
                                ></div>
                            ),
                        }}
                    />
                </div>

                {this.state.searchValue.length > 2 ?
                    <div>
                        {this.state.searchLoading && <Loader />}
                        {!this.state.searchLoading && searchData}
                        <TooltipWrapper label={'LBL_TOGGLE_FILTERS'} placement={'left'}>
                            <div className={openButtonClass} onClick={() => sAction.toggleCalcFilter()}/>
                        </TooltipWrapper>
                    </div> :
                    <div>
                        <div key={`calBoardHeader-filters`} className={`calBoardHeader ${visibClass}`}
                            onClick={() => this.handleTabSelect('filter')}>
                            <div className="icon-CalSettingsIcon calHeaderIcon"></div>
                            <span className="calFilterTitle">&nbsp;{sAction.translate('LBL_SETTINGS', 'Calendar')}</span>
                        </div>
                        <div className={`calSettingSection ${visibClass}`}>
                            <div className={'calFiltersRow sectionRow' + (!+filters.unpackActivities ? ' notSelected' : '')}>
                                <TickBox className="calFiltersTick" name="unpackActivities" checked={!!filters.unpackActivities}
                                    onChange={(value) => this.updateFilters('unpackActivities', value)}/>
                                <span className='icon-f212 calFilterIcon'></span>
                                <span className="">{sAction.translate('LBL_UNPACK_ACTIVITIES', 'Calendar')}</span>
                            </div>
                        </div>
                        <div className={'calBoardHeader ' + visibClass}>
                            <div key={`calBoardHeader-filters`} className="calBoardHeader"
                                onClick={() => this.handleTabSelect('filter')}>
                                <div className="icon-CalFilterIcon calHeaderIcon"></div>
                                <span className="calFilterTitle">&nbsp;{sAction.translate('LBL_FILTERS_TITLE', 'Calendar')}</span>
                            </div>
                            {this.state.userIsOrdersPlanner === '1' && (
                                <Button key={`calBoardHeader-events`} className="calBoardHeader"
                                    onClick={() => this.handleTabSelect('events')}>
                                    <div className="icon-calendar calHeaderIcon"></div>
                                    <span className="calFilterTitle">&nbsp;{sAction.translate('LBL_EVENTS_TITLE', 'Calendar')}</span>
                                </Button>
                            )}
                        </div>
                        {this.state.tabSelected === 'filter' && (
                            filtersRender
                        )}
                        {(this.state.tabSelected === 'events' && this.state.userIsOrdersPlanner === '1') && (
                            <CalExternalEvents key={`CalExternalEvents`} data={filters} events={this.state.events}
                                reload={this.props.data.filters.reloadExternal}/>
                        )}
                        <TooltipWrapper label={'LBL_TOGGLE_FILTERS'} placement={'left'}>
                            <div className={openButtonClass} onClick={() => sAction.toggleCalcFilter()}/>
                        </TooltipWrapper>
                    </div>
                }
            </div>
        );
    }

    /**
     *
     * @param {string} secRowClass
     * @param {string} StateClass
     * @param {any} data
     * @param {object} pomRender
     * @param {string} show
     * @param {string} lbl
     * @returns {JSX}
     */
    buildFilterSection(secRowClass, StateClass, data, pomRender, show, lbl) {
    // TODO predelat do komponenty
        return (
            <div className="calFiltersSection">
                <div className={'calFiltersRow sectionRow' + secRowClass}>
                    <TickBox
                        className="calFiltersTick"
                        name={show}
                        checked={!!data[show]}
                        onChange={(value) => this.updateFilters(show, value)}/>
                    {this.getEventIcon(show)}
                    <span className="">
                        {sAction.translate(lbl, 'Calendar')}
                    </span>
                </div>
                <DropdownAnimation open={data[show]} startingHeight={data[show]? 35 * pomRender.size + 20 : 0} >
                    <div className={StateClass}>
                        {pomRender}
                    </div>
                </DropdownAnimation>
            </div>
        );
    }

    handleTabSelect = (tabID) => {
        this.setState({tabSelected: tabID});
    };

    /**
     *
     * @param {object} opt option
     * @param {string} type module
     * @param {any} data
     * @returns {JSX}
     */
    generateTickBox(opt, type, data) {
        // get state color
        let module = type;
        let color = null;
        if (type === 'meet') module = 'meeting';
        const colors = sAction.dataGet(`calendar/${module}View/timeline/colors`);
        if (colors) {
            color = sAction.convertToPastelHSL(colors.get(opt.value));
        }

        let selVal = false;
        let itemClass = ' notSelected';
        const typeSelector = `${type}States`;
        if (data[typeSelector]?.indexOf(opt.value) >= 0) {
            selVal = true;
            itemClass = ' selected';
        }
        return (
            <div
                key={`${typeSelector}${opt.value}`}
                className={'calFiltersRow ' + this.getEventClass(opt.value) + itemClass}
                style={{
                    borderLeft: `4px solid ${(color || '#E4ECF2')}`,
                }}
            >
                <TickBox className="calFiltersStateTick"
                    checked={!!selVal}
                    onChange={(value) => this.updateStateFilters(`${typeSelector}`, opt.value, value)}/>
                <span className="calFilterLbl">{opt.label}</span>
            </div>
        );
    }
}

CalFilters.propTypes = {
    data: PropTypes.object.isRequired,
};

export default CalFilters;
