import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import moment from 'moment';

import sAction from 'sAction';

export default function NewActivityMenu({date, anchorEl, open, handleClose, userID, userName, offsetX, offsetY}) {
    if (!userID || userID === 'my_calendar') {
        userID = sAction.dataGet('conf/user/id');
    }
    if (!userName) {
        userName = sAction.dataGet('conf/user/name');
    }

    /**
     * returns initial invitees for new activity
     *
     * @returns {object} invitees
     */
    const getInvitees = () => {
        const invitees = {
            relatedInv: {Users: [], Contacts: [], Leads: []},
        };
        invitees.relatedInv.Users.push(sAction.dataGet('calendar/setting/currentUserInv'));

        if (userID !== sAction.dataGet('calendar/setting/user_id')) {
            invitees.relatedInv.Users.push(sAction.dataGet(`calendar/setting/sharedUserInv/${userID}`) || {id: userID, name: userName});
        }

        return invitees;
    };

    /**
     * handle selection of activity module
     * @param {string} module
     */
    const handleSelection = (module) => {
        const dateTime = moment(date);

        const initFields = [
            {name: 'assigned_user_id', fieldName: 'assigned_user_name', value: {id: userID, name: userName}, type: 'relate_simple'},
        ];
        switch (module) {
            case 'Calls':
                initFields.push({name: 'date_start', value: dateTime.format('YYYY-MM-DD HH:mm:ss'), type: 'field'});
                initFields.push({name: 'date_end', value: dateTime.add(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), type: 'field'});
                initFields.push({name: 'duration_hours', value: 0, valueMinutes: 15, type: 'hoursMinutes'});
                initFields.push({value: {invitees: getInvitees()}, type: 'customData'});
                break;
            case 'Meetings':
                initFields.push({name: 'date_start', value: dateTime.format('YYYY-MM-DD HH:mm:ss'), type: 'field'});
                initFields.push({name: 'date_end', value: dateTime.add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'), type: 'field'});
                initFields.push({name: 'duration_hours', value: 0, valueMinutes: 30, type: 'hoursMinutes'});
                initFields.push({value: {invitees: getInvitees()}, type: 'customData'});
                break;
            case 'Tasks':
                initFields.push({name: 'date_start', value: dateTime.format('YYYY-MM-DD HH:mm:ss'), type: 'field'});
                break;
            default:
                break;
        }

        sAction.rightPanelNewRecord(module, initFields);
        handleClose();
    };

    const anchorPosition = {top: offsetY ?? 0, left: offsetX ?? 0};
    if (anchorEl) {
        anchorPosition.top = offsetY ?? anchorEl.getBoundingClientRect().top;
        anchorPosition.left = offsetX ?? anchorEl.getBoundingClientRect().left + anchorEl.offsetWidth / 2;
    }

    return (
        <Menu
            anchorEl={anchorEl}
            anchorReference={(offsetX || offsetY)? 'anchorPosition' : 'anchorEl'}
            anchorPosition={anchorPosition}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuItem onClick={() => handleSelection('Meetings')}>
                <span className="icon-Meetings calHeaderIcon" />
                {sAction.translate('LBL_TITLE_CREATE_NEW_MEETING', 'Calendar')}
            </MenuItem>
            <MenuItem onClick={() => handleSelection('Calls')}>
                <span className="icon-Calls calHeaderIcon" />
                {sAction.translate('LBL_TITLE_CREATE_NEW_CALL', 'Calendar')}
            </MenuItem>
            <MenuItem onClick={() => handleSelection('Tasks')}>
                <span className="icon-Tasks calHeaderIcon" />
                {sAction.translate('LBL_TITLE_CREATE_NEW_TASK', 'Calendar')}
            </MenuItem>
        </Menu>
    );
}

NewActivityMenu.propTypes = {
    date: PropTypes.string,
    anchorEl: PropTypes.node,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    userID: PropTypes.string,
    userName: PropTypes.string,
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
};
