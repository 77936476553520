/**
 * @param {object} data
 * @param cancelPreviousRequest
 * @this sAction
 */
export default function reloadSubpanel(data, cancelPreviousRequest = true) {
    const paramData = {
        record: data.parentId,
        module: data.parentModule,
        subpanel: data.module,
        relName: data.relName,
        userPreferences: {category: `${data.parentModule}_subpanel`, way: `all/${data.module}/open`, contents: true},
        rightPanel: data.prefix === 'rightPanel/data/detail',
    };

    if (this.getStorage('Accounts-subpanel-branch')) {
        paramData.lsData = this.getStorage('Accounts-subpanel-branch');
    }

    if (data.module === 'Activities' || data.module === 'History') {
        paramData.getActivityModules = data.getActivityModules;
    }

    this.rest.post('returnSubpanelData', paramData, (returnData) => {
        if (returnData.status === true) {
            if (data.def?.get?.('readonly')) {
                returnData.message.data.readonly = true;
            }
            const action = {
                type: 'LOADSUBPANEL',
                subpanel: returnData.message.data,
                parent: data.parentModule,
                parentId: data.parentId,
                prefix: data.prefix,
                way: data.way,
            };
            this.dataSetCustom(action);
            if (data.subpanelAction === 'new_relate') {
                this.custom('subpanel', {module: data.parentModule, subpanelData: returnData});
            }
            this.unLoad();
        } else {
            this.error(this.translate(returnData.errorMessage.text));
            this.dsClear();
            this.dsAdd('set', data.way + '/def/open', false);
            this.dsAdd('set', data.way + '/def/load', false);
            this.dsProcess();
        }
    }, cancelPreviousRequest);
}
