import {Map} from 'immutable';

/**
 * @param {object} paramData
 * @param {function} callback
 * @this sAction
 * @button DetailView
 */
export default function saveRecord(paramData, callback = null) {
    const self = this;
    const id = paramData.id;
    this.saveRecordBase(paramData, (data) => {
        if (data.state !== true) {
            self.error(data.msg);
            return;
        }

        const msg = data.data;
        if (!this.checkDeletedRecord(msg, paramData)) {
            return;
        }

        self.dsClear();
        self.dsAdd('set', paramData.prefix + '/changes', {fields: new Map(), files: []});
        // this is weird, i want call detail load function, but have to set false to really call it
        self.dsAdd('set', paramData.prefix + '/shouldCallHooks/shouldCallLoadHook', false);
        self.dsProcess();

        if (typeof callback === 'function') {
            callback(msg.record);
            return;
        }

        if (paramData.type === 'rightPanel') {
            this.dataSet('rightPanel/reloadOnClose', true);
            if (this.dataGet('rightPanel/closeOnSave') === true) {
                this.closeRightPanel();
                this.route();

                return;
            }

            this.reloadListOnClose();
        }

        if (paramData.type === 'rightPanel' || paramData.type === 'popup') {
            if (['', 'calendar', 'home'].includes(self.getViewName()) && ['Calls', 'Meetings', 'Tasks'].includes(msg.module)) {
                self.calPlanReload('external');
                this.dataSet('rightPanel/reloadOnClose', false);
            }

            const otherData = {
                subpanels: false,
                cancelPreviousRequest: data['cancelPreviousRequest'] ?? true,
            };
            paramData.id = msg.record;
            self.routeDetailView(paramData, otherData);
        } else if (!id) {
            window.location.hash = '#detail/' + msg.module + '/' + msg.record;
        } else {
            const otherData = {
                cancelPreviousRequest: data['cancelPreviousRequest'] ?? true,
            };
            self.routeDetailView(paramData, otherData);
        }
    });
}
