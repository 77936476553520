/**
 * @param {object} data
 * @param {string} data.module
 * @param {string} data.parentModule
 * @param {string} data.parentId
 * @param {string} data.parentName
 * @param {string} data.prefix
 * @param {any} data.rel
 * @param {object|array} data.relateSimple
 * @this sAction
 */
export default function newSubRecord(data) {
    let {module, parentModule, parentId, parentName, prefix, rel, relateSimple} = data;
    if (!module || !parentModule || !parentId || !parentName || !prefix) {
        console.error('Invalid data');

        return;
    }
    this.detailPredefinedFields = [];

    if (module === 'Calls' || module === 'Meetings') {
        const customData = {
            invitees: {
                relatedInv: {Users: [], Contacts: [], Leads: []},
            },
        };

        if (parentModule === 'Contacts' || parentModule === 'Leads' || parentModule === 'Users') {
            customData.invitees.relatedInv[parentModule].push({
                email: this.dataGet(`${prefix}/fields/email1/value`),
                id: parentId,
                name: parentName,
                title: (parentModule === 'Contacts')? this.dataGet(`${prefix}/fields/title/value`) : '',
                phone_mobile: this.dataGet(`${prefix}/fields/phone_mobile/value`),
                phone_work: this.dataGet(`${prefix}/fields/phone_work/value`),
            });
        }

        this.detailPredefinedFields.push({type: 'customData', value: customData});
    }

    if (['Calls', 'Meetings', 'Tasks', 'Notes'].includes(module)) {
        if (parentModule === 'Contacts') {
            this.detailPredefinedFields.push({
                type: 'relate_simple',
                fieldName: 'contact_name',
                name: 'contact_id',
                value: {'id': parentId, 'name': parentName},
            });

            const parentAccount = this.dataGet(`${prefix}/fields/account_name`);
            if (parentAccount) {
                const accountId = parentAccount.def.get('id_value');
                const accountName = parentAccount.get('value');
                if (accountId) {
                    parentName = accountName;
                    parentId = accountId;
                    parentModule = 'Accounts';
                }
            }
        }

        this.detailPredefinedFields.push({
            type: 'parent',
            name: 'parent_name',
            parentName,
            parentId,
            parentModule,
        });
    } else if (relateSimple) {
        this.detailPredefinedFields.push({
            type: 'relate_simple',
            ...relateSimple,
        });
    } else {
        if (!rel) {
            console.warn('nejsou zadány inforamce o vazbě');

            return;
        }
        this.detailPredefinedFields.push({
            type: 'relate',
            module: parentModule,
            id: parentId,
            value: parentName,
            rel,
        });
    }

    this.rightPanelNewRecord(module);
}
