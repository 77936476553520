import React from 'react';
import PureComponent from '../pure';

import sAction from 'sAction';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, {Draggable} from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import moment from 'moment';
import AcmDate from '../formElements/AcmDate';
import PropTypes from 'prop-types';
import Button from 'ROOT/src/components/formElements/Button';
import {formatPhoneNumber, formatPhoneNumberIntl, getCountryCallingCode} from 'react-phone-number-input';

/**
 * Uitečné podpůrné funkce pro práci s kalendářem:
 * sAction.clearCalDetailStore() - procisti data co se zobrazuji v popupu
 * sAction.calPlanReload('all') - donuti prenacist kalendar / externi eventy / vse ('all' / 'calendar' / 'external')
 */

class CalBody extends PureComponent {
    constructor(props) {
        super(props);

        this['calRef' + [props.userID]] = React.createRef();

        this.defaultView = sAction.dataGet('calendar/setting/initView') ?? 'month';
        this.useOrderLines = false; // Lines in meeting - Applied in project "Plas"
        this.getMonthsBefore = 1;
        this.getMonthsAfter = 1;
        this.getDaysBefore = 14;
        this.getDaysAfter = 14;

        this.state = {
            calViewType: this.props.data.calViewType ?? this.defaultView, // this.props.data.calViewType
            currentView: this.props.data.calViewType ?? this.defaultView,
            rerender: false,
            ready: false,
            openPicker: false,
            userID: props.userID,
            calRef: 'calRef' + [props.userID],
            prefix: 'calendar',
            calendarEvents: [],
            events: [],
            loaded: false,
            loadedDates: {
                from: null,
                to: null,
            },
            loadedDatesInit: {
                from: null,
                to: null,
            },
            unpackActivities: false,
        };

        this.mapLoaded = {};
        this.mapLoadedInit = {};
        this.updateTimeout = null;
        this.weekNumbers = false;
    }

    // calendarRef = React.createRef();
    componentDidMount() {
        this.calendarInit(true);
    }

    componentDidUpdate(prevProps, prevState) {
        const unpackActivities = sAction.dataGet('calendar/setting/unpackActivities');
        if (unpackActivities !== prevState.unpackActivities) {
            this.setState({unpackActivities: unpackActivities});
        }
    }

    /**
   *
   * @param {bool} changeView
   */
    calendarInit(changeView = false) {
    // sAction.load()
        const sharedView = sAction.dataGet('calendar/setting/sharedView');
        const calUserId = sAction.dataGet('calendar/setting/user_id');

        if (!this[this.state.calRef].current) {
            console.warn('>> calendarInit ... no ref', this.state.calRef, 'user_id:', this.state.userID);
            sAction.error(sAction.translate('LBL_CALENDAR_INIT_ERROR', 'Calendar') + 'user_id: ' + this.state.userID);

            return;
        }
        const calendarApi = this[this.state.calRef].current.getApi();
        const currDate = calendarApi.getDate();
        const from = moment(currDate).add(-1 * this.getMonthsBefore, 'months').add(-1 * this.getDaysBefore, 'days').format('YYYY-MM-DD');
        const to = moment(currDate).add(this.getMonthsAfter, 'months').endOf('month').add(this.getDaysAfter, 'days').format('YYYY-MM-DD');

        // when user switches from shared calendar to his own calendar, ignore reloading other calendars so they don't rewrite his calendar
        if (sharedView === false &&
            changeView === false &&
            calUserId !== this.props.userID &&
            calUserId !== 'my_calendar' &&
            this.state.userID !== 'my_calendar'
        ) {
            console.warn('>> [SKIP] calendarInit ... user_id:', this.state.userID, 'is not same as calendar/setting/user_id:', calUserId);
            return;
        }

        sAction.calInit(this.props.cssSelector, this.props.way, this.props.userID, this.props.language, from, to, (data) => {
            // console.log('>> calendarInit ... data json', JSON.stringify(data));
            // const data = JSON.parse(JSON.stringify(dataSrc));
            // pridava eventy podle mapy loaded eventu stejne tak calendarUpdateEvents,
            // kalendar se hromdne nemaze jenom dpluje podle toho co uzivatel nacte
            // kdyz pridava jeden novy item tak nastavi flag v mape na false -> uz byl nacteny ale je potreba prerenderovat
            // jinak flag true -> uz byl nacteny a je v kandari ignorovat
            // mapy jsou dve mapLoaded a mapLoadedInit, mapLoadedInit se promazava pri reloadu kalendare,
            // protoze init data se chovai divne a nezustanou oprodi addEvent dat
            let lastIdAdded = null;
            let addedCount = 0;
            for (let i = 0; i < data.length; i++) {
                if (this.mapLoaded[data[i].id] && this.mapLoaded[data[i].id] === true ||
                    this.mapLoadedInit[data[i].id] && this.mapLoadedInit[data[i].id] === true) {
                    data.splice(i, 1);
                    i--;
                } else {
                    this.mapLoadedInit[data[i].id] = true;
                    lastIdAdded = data[i].id;
                    addedCount++;
                }
            }

            if (addedCount === 1) {
                this.mapLoaded[lastIdAdded] = false;
                this.mapLoadedInit[lastIdAdded] = false;
            }

            if (changeView) {
                const view = this.props.way ?
                    sAction.dataGet(this.props.way + '/data/options/initView') :
                    sAction.dataGet('calendar/setting/initView');
                this.changeView(view); // Do not judge me
            }

            this.setState({
                calendarEvents: data, // nemuze byt pres API, jelikoz by to po renderu zmizelo
                rerender: false,
                ready: true,
                loadedDatesInit: {
                    from: from,
                    to: to,
                },
                loadedDates: {
                    from: from,
                    to: to,
                },
            });
            sAction.unLoad(); // teprve nyni je vse nacteno podle nastaveni uzivatele
            this.setState({loaded: true});
        });

        this.setExternalEvents();
    }

    /**
   *
   * @param {string} from
   * @param {string} to
   */
    calendarUpdateEvents(from, to) { // jede pres API, takze nevynucuje prerenderovani
        const getFrom = moment(from).format('YYYY-MM-DD');
        const getTo = moment(to).format('YYYY-MM-DD');

        setTimeout(() => {
            sAction.calInit(this.props.cssSelector, this.props.way, this.props.userID, this.props.language, getFrom, getTo, (data) => {
                const calendarApi = this[this.state.calRef].current.getApi();
                // calendarApi.removeAllEvents()

                // pridat jenom nove eventy co jesete nejsou nactene a nemazat cely kalendar
                data.forEachObject((val) => {
                    // toto trva asi 12ms nez prida event takze pri 300 eventech to trva asi 3 600ms
                    // a je to blocking takze jakmile zacne pridavat eventy tak calendar zamrzne asi s tim nic neudelame
                    // setTimeout 0 to aspon rozdělí trochu že se nezasekne cely kalendar
                    setTimeout(() => {
                        if (this.mapLoaded[val.id] === undefined && this.mapLoadedInit[val.id] === undefined) {
                            calendarApi.addEvent(val);
                            this.mapLoaded[val.id] = true;
                        }
                    }, 0);
                });

                sAction.unLoad(); // teprve nyni je vse nacteno podle nastaveni uzivatele
                setTimeout(() => {
                    this.setState({
                        loaded: true,
                        loadedDates: {
                            from: moment(getFrom) > moment(this.state.loadedDates.from) ? getFrom : this.state.loadedDatesInit.from,
                            to: moment(getTo) < moment(this.state.loadedDates.to) ? getTo : this.state.loadedDatesInit.to,
                        },
                    });
                }, 250);
            });
        }, 0);
    }

    /**
   *
   * @param {string} date
   */
    datePickerHandle(date) {
        const calendarApi = this[this.state.calRef].current.getApi();
        calendarApi.gotoDate(moment(date).format('YYYY-MM-DD'));
    }

    pickADate = () => {
    // TODO tohle taky neni zrovna fajn reseni
        document.getElementById('clickMeIfYouDare' + this.state.userID).querySelector('.acmDateFreeFormatIconBox').click();
    };

    // Print the page ctrp+p
    printAgenda = () => {
        window.print();
    };

    handleEventAdd = () => {
        this.handleDayClick();
    };

    handleEventReceive = (arg) => {
        arg.event.remove(); // aby v kalendari nezustal vyset jinak nastylovany event, dokud ho nepotvrdi nebo i potom

        this.setEventFieldsBeforeReceive(arg.event, this.state, () => {
            sAction.popup('calQuickCreate', {
                onClose: sAction.clearCalDetailStore,
                prefix: this.state.prefix, /* , event : arg.event._def*/
            });
        });
    };

    setEventFieldsBeforeReceive = (event, state, cb) => {
        const calendarApi = this[this.state.calRef].current.getApi();
        let eventStart = '';

        // TODO zjistit, jestli by nestacilo jen jedno z toho, nebo neco jineho podle ceho to poznavat
        if (sAction.dataGet('calendar/calViewType') === this.defaultView ||
            calendarApi.currentDataManager.state.currentViewType === this.defaultView) {
            eventStart = moment(event.start).format('YYYY-MM-DD');
            eventStart = eventStart + ' ' + moment().format('HH:mm:ss'); // aby jsme v mesicnim pohledu nevytvareli eventy na pulnoc
        } else {
            eventStart = moment(event.start).format('YYYY-MM-DD HH:mm:ss');
        }

        const meetingType = 'montaz';
        const durationHours = '1';
        const durationMinutes = '0';

        // neodradkovavat, pridalo by to tam \n
        let address = '';
        address += event.extendedProps.address.street ?? '';
        address += event.extendedProps.address.city ? `, ${event.extendedProps.address.city}` : '';
        address += event.extendedProps.address.postalCode ? `, ${event.extendedProps.address.postalCode}` : '';
        address += event.extendedProps.address.state ? `, ${event.extendedProps.address.state}` : '';

        const title = (this.useOrderLines && event.extendedProps?.orderLines && event.extendedProps.orderLines[0]?.olName) ?
            '[M] ' + event._def.title + ' - ' + event.extendedProps.orderLines[0].olName :
            '[M] ' + event._def.title;

        sAction.dsClear();
        /**
     * Musi se nastavovat pro kazdy pohled zvlast. Jsou tam ruzna pole a ruzne podminky
     * - Mohlo by se trosku predelat, ale to by trvalo priliz dlouho... az bude cas...
     * */

        // Meetings
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/name/value', title);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/name', title);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/duration_hours/value', durationHours);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/duration_hours', durationHours);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/meeting_type/value', meetingType);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/meeting_type', meetingType);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/duration_hours/def/minutesValue', durationMinutes);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/duration_minutes', durationMinutes);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/date_start/value', eventStart);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/date_start', eventStart);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/location/value', address);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/location', address);

        sAction.dsAdd('set', this.state.prefix + '/meetingView/customData/oID', event.extendedProps.id);

        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/description', event.extendedProps.aContact);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/description/value', event.extendedProps.aContact);

        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/assigned_user_name/value', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/assigned_user_name/def/id_value', state.userID);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/relate/assigned_user_name', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/assigned_user_id', state.userID);

        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/parent_name/value', event.extendedProps.aName);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/parent_name/def/parent_type_value', 'Accounts');
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/parent_name/def/id_value', event.extendedProps.aID);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/parent_type', 'Accounts');
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/parent_id', event.extendedProps.aID);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/relate/parent_name', event.extendedProps.aName);

        if (this.useOrderLines) sAction.dsAdd('set', this.state.prefix + '/meetingView/orderLines', event.extendedProps.orderLines);

        // Calls
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/name/value', title);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/name/value', title);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/duration_hours/value', durationHours);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/duration_hours/value', durationHours);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/duration_hours/def/minutesValue', durationMinutes);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/duration_hours/def/minutesValue', durationMinutes);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/date_start/value', eventStart);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/date_start/value', eventStart);

        sAction.dsAdd('set', this.state.prefix + '/callView/fields/assigned_user_name/value', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/assigned_user_name/def/id_value', state.userID);
        sAction.dsAdd('set', this.state.prefix + '/callView/changes/fields/relate/assigned_user_name', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/callView/changes/fields/assigned_user_id', state.userID);

        sAction.dsAdd('set', this.state.prefix + '/callView/fields/parent_name/value', event.extendedProps.aName);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/parent_name/def/parent_type_value', 'Accounts');
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/parent_name/def/id_value', event.extendedProps.aID);
        sAction.dsAdd('set', this.state.prefix + '/callView/changes/fields/parent_type', 'Accounts');
        sAction.dsAdd('set', this.state.prefix + '/callView/changes/fields/parent_id', event.extendedProps.aID);
        sAction.dsAdd('set', this.state.prefix + '/callView/changes/fields/relate/parent_name', event.extendedProps.aName);

        // Tasks
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/name/value', title);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/name/value', title);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/duration_hours/value', durationHours);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/duration_hours/value', durationHours);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/duration_hours/def/minutesValue', durationMinutes);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/duration_hours/def/minutesValue', durationMinutes);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/date_start/value', eventStart);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/date_start/value', eventStart);

        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/assigned_user_name/value', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/assigned_user_name/def/id_value', state.userID);
        sAction.dsAdd('set', this.state.prefix + '/taskView/changes/fields/relate/assigned_user_name', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/taskView/changes/fields/assigned_user_id', state.userID);

        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/parent_name/value', event.extendedProps.aName);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/parent_name/def/parent_type_value', 'Accounts');
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/parent_name/def/id_value', event.extendedProps.aID);
        sAction.dsAdd('set', this.state.prefix + '/taskView/changes/fields/parent_type', 'Accounts');
        sAction.dsAdd('set', this.state.prefix + '/taskView/changes/fields/parent_id', event.extendedProps.aID);
        sAction.dsAdd('set', this.state.prefix + '/taskView/changes/fields/relate/parent_name', event.extendedProps.aName);

        sAction.dsProcess();
        cb();
    };

    handleEventUpdate = (arg) => {
    // sAction.load()
        const event = arg.event;

        const fields = {
            'date_start': moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
            'date_end': moment(event.end).format('YYYY-MM-DD HH:mm:ss'),
        };
        const params = {
            'module': event.extendedProps.act_type,
            'checkInvCollision': true,
        };

        sAction.calSaveActivity(event.id, fields, params, this.reschedCallBack/* , revertFunc*/);
    };

    handleEventClick = (arg) => {
        sAction.load(); // nevim why ale na mobilku to zhodi cely komponent "magic"
        const event = arg.event;

        if (event.extendedProps.orderLines && this.useOrderLines) {
            sAction.dsClear();
            sAction.dsAdd('set', this.state.prefix + '/meetingView/orderLines', event.extendedProps.orderLines);
            sAction.dsProcess();
        }

        // aaaaaand open! (Claptrap) - otevřít popup s detailem
        sAction.displayQuickEdit({
            module: event.extendedProps.act_type,
            record: event._def.publicId,
            prefix: this.state.prefix,
            color: event.extendedProps.act_type + 'CalTitle', // I am sooo sorry
            header: event.title,
        });
    };

    /**
     * moment formatDuration
     * @param {moment} duration
     * @returns {string|undefined}
     */
    formatDuration(duration) {
        const parts = [];

        // return nothing when the duration is falsy or not correctly parsed (P0D)
        if (!duration || duration.toISOString() === 'P0D') return;

        if (duration.years() >= 1) {
            const years = Math.floor(duration.years());
            parts.push(years+' '+(years > 1 ? sAction.translate('LBL_TIME_YEARS', 'Calendar') :
                sAction.translate('LBL_TIME_YEAR', 'Calendar')));
        }

        if (duration.months() >= 1) {
            const months = Math.floor(duration.months());
            let monthString = sAction.translate('LBL_TIME_MONTH', 'Calendar');
            if (months > 1 && months < 5) {
                monthString = sAction.translate('LBL_TIME_MONTHS2', 'Calendar');
            } else {
                monthString = sAction.translate('LBL_TIME_MONTHS', 'Calendar');
            }
            parts.push(`${months} ${monthString}`);
        }

        if (duration.days() >= 1) {
            const days = Math.floor(duration.days());
            let dayString = sAction.translate('LBL_TIME_DAY', 'Calendar');
            if (days > 1 && days < 5) {
                dayString = sAction.translate('LBL_TIME_DAYS2', 'Calendar');
            } else {
                dayString = sAction.translate('LBL_TIME_DAYS', 'Calendar');
            }

            parts.push(`${days} ${dayString}`);
        }

        if (duration.hours() >= 1) {
            const hours = Math.floor(duration.hours());
            parts.push(hours+' '+(hours > 1 ? 'h' : 'h'));
        }

        if (duration.minutes() >= 1) {
            const minutes = Math.floor(duration.minutes());
            parts.push(minutes+' '+(minutes > 1 ? 'min' : 'min'));
        }

        if (duration.seconds() >= 1) {
            const seconds = Math.floor(duration.seconds());
            parts.push(seconds+' '+(seconds > 1 ? 'sec' : 'sec'));
        }

        return parts.join(' ');
    }

    handleEventDidMount = (arg) => {
        const event = arg.event;
        const element = arg.el;
        const view = arg.view;
        // mesicni pohled proste nechce brat v potaz nastaveni pozadi primo na eventu TODO: zjistit proc

        // get secondary color
        const primaryColor = event.extendedProps?.eventBackgroundColor;
        const textColor = sAction.convertToPastelHSL(primaryColor, 1, 'dark');
        let statusColor = '#1D1DCC';
        let statusColorShadow = '';

        // this colors are no longer in use now are used modified timeline colors
        switch (event.extendedProps?.status) {
            case 'Held':
                statusColor = event.extendedProps.act_type === 'Calls' ? '#4ACC00' : '#FFC30B';
                break;
            case 'Not Held':
            case 'NotHeld':
            case 'Failed':
            case 'Deferred':
                statusColor = '#E92222';
                break;
            case 'Confirmed':
            case 'Success':
            case 'Completed':
                statusColor = '#4ACC00';
                break;
            case 'Planned':
            case 'Progress':
                statusColor = '#1D1DCC';
                break;
            default:
        }

        // Meetings -> meetingView
        let module = event.extendedProps.act_type.toLowerCase();
        if (module.slice(-1) === 's') module = module.slice(0, -1);
        const timeline = this.props.data.get(module + 'View').get('timeline').toJS();
        // const timeline = this.props.data.get(module + 'View')

        const timelineColor = timeline?.colors?.[event.extendedProps?.status];

        if (timelineColor) {
            statusColor = sAction.convertToPastelHSL(timelineColor);
            statusColorShadow = sAction.convertToPastelHSL(timelineColor, .5);
        } else {
            statusColor = sAction.convertToPastelHSL(statusColor);
            statusColorShadow = sAction.convertToPastelHSL(statusColor, .5);
        }

        // if(view.type == this.defaultView) {
        // element.style.background = 'linear-gradient(135deg, ' + statusColor + ' 0%, ' + statusColor + 'CC 17%, ' + primaryColor + ' 33%)'
        element.style.background = primaryColor;
        // }

        const fcText = element.querySelector('.fc-event-title');
        if (fcText) fcText.style.cssText = `color: ${textColor};`;

        if (event.extendedProps.act_type === 'Meetings') {
            element.classList.add('eventMeeting');
            element.querySelector('.fc-event-title')
                ?.insertAdjacentHTML('beforebegin', `<div class='calEventIconCircle'
        style='background-color: ${statusColor}; box-shadow: 0 0 12px ${statusColorShadow}'>
        <div class='calEventIcon icon-Meetings' /></div>`);
            // element.querySelector('.fc-event-title')
            // ?.insertAdjacentHTML('beforebegin', `<div class='calEventIconCirclePlaceholder'></div>`);
        } else if (event.extendedProps.act_type === 'Calls') {
            element.classList.add('eventCall');
            element.querySelector('.fc-event-title')
                ?.insertAdjacentHTML('beforebegin', `<div class='calEventIconCircle'
        style='background-color: ${statusColor}; box-shadow: 0 0 12px ${statusColorShadow}'>
        <div class='calEventIcon icon-Calls' /></div>`);
            // element.querySelector('.fc-event-title')
            // ?.insertAdjacentHTML('beforebegin', `<div class='calEventIconCirclePlaceholder'></div>`);
        } else if (event.extendedProps.act_type === 'Tasks') {
            element.classList.add('eventTask');
            element.querySelector('.fc-event-title')
                ?.insertAdjacentHTML('beforebegin', `<div class='calEventIconCircle'
        style='background-color: ${statusColor}; box-shadow: 0 0 12px ${statusColorShadow}'>
        <div class='calEventIcon icon-Tasks' /></div>`);
            // element.querySelector('.fc-event-title')
            // ?.insertAdjacentHTML('beforebegin', `<div class='calEventIconCirclePlaceholder'></div>`);
        }
        if (event.extendedProps.assigned_user_id && sAction.dataGet('conf/user/orders_planner') === '1') {
            element.querySelector('.fc-event-title')
                ?.insertAdjacentHTML('afterend',
                    '<div class="fc-event-assignedUser">' +
          '<div class="hr-line-solid-no-margin" style="color: ' + textColor + '"></div>' +
          '<div class=\'calEventIcon icon-Users\'></div><span style="font-size: 0.9em">' +
          event.extendedProps.assigned_user_name +
          '</span>' +
          '</div>' +
          '</div>');
        }
        const exp = event.extendedProps;
        let htmlAppend = '';

        const unpackActivities = this.props.data?.setting?.get('unpackActivities');
        const className = unpackActivities && view.type === 'month' ? 'eventMoreInfo-active' : 'eventMoreInfo';
        const timeLine = element.querySelector('.fc-list-event-time');
        if (exp.parent_name || exp.invitesCnt > 0) {
            htmlAppend = `<div class="${className}">
            <div class="hr-line-solid-no-margin" style="color: ${textColor}"></div>`;

            if (exp.parent_name) {
                htmlAppend += `<div class="infoLine"><span class='calInfoIcon icon-${exp.parent_type}' style="color: ${textColor}"></span>
                <span style="color: ${textColor}">${exp.parent_name}</span>`;
                htmlAppend += timeLine && exp.parent_phone_mobile && exp.parent_type === 'Contacts' ? `<br>
                <span class='calInfoIcon icon-phone' style="color: ${textColor}"></span>
                <span>${this.formatPhoneNum(exp.parent_phone_mobile)}</span>` : '';
                htmlAppend += timeLine && exp.parent_phone_work && exp.parent_type === 'Contacts' ? `<br>
                <span class='calInfoIcon icon-mobile' style="color: ${textColor}"></span>
                <span>${this.formatPhoneNum(exp.parent_phone_work)}</span>` : '';
                htmlAppend += `</div>`;
            }

            if (exp.invites) {
                if (exp.invites?.Contacts && exp.invites.Contacts.length > 0) {
                    exp.invites.Contacts.forEach((contact) => {
                        htmlAppend += `<div class="infoLine">
                        <span class='calInfoIcon icon-Contacts' style="color: ${textColor}"></span>
                        <span style="color: ${textColor}">${contact.name}</span>`;
                        htmlAppend += timeLine && contact.phone_mobile ? `<br>
                        <span class='calInfoIcon icon-phone' style="color: ${textColor}"></span>
                        <span>${this.formatPhoneNum(contact.phone_mobile)}</span>` : '';
                        htmlAppend += timeLine && contact.phone_work ? `<br>
                        <span class='calInfoIcon icon-mobile' style="color: ${textColor}"></span>
                        <span>${this.formatPhoneNum(contact.phone_work)}</span>` : '';
                        htmlAppend += `</div>`;
                    });
                }
                if (exp.invites?.Users && exp.invites.Users.length > 0) {
                    exp.invites.Users.forEach((user) => {
                        htmlAppend += `<div class="infoLine"><span class='calInfoIcon icon-Users' style="color: ${textColor}"></span>
                        <span style="color: ${textColor}">${user.name}</span>`;
                        htmlAppend += timeLine && user.phone_mobile ? `<br>
                        <span class='calInfoIcon icon-phone' style="color: ${textColor}"></span>
                        <span>${this.formatPhoneNum(user.phone_mobile)}</span>` : '';
                        htmlAppend += timeLine && user.phone_work ? `<br>
                        <span class='calInfoIcon icon-mobile' style="color: ${textColor}"></span>
                        <span>${this.formatPhoneNum(user.phone_work)}</span>` : '';
                        htmlAppend += `</div>`;
                    });
                }
                if (exp.invites?.Leads && exp.invites.Leads.length > 0) {
                    exp.invites.Leads.forEach((lead) => {
                        htmlAppend += `<div class="infoLine"><span class='calInfoIcon icon-Leads' style="color: ${textColor}"></span>
                        <span style="color: ${textColor}">${lead.name}</span>`;
                        htmlAppend += timeLine && lead.phone_mobile ? `<br>
                        <span class='calInfoIcon icon-phone' style="color: ${textColor}"></span>
                        <span>${this.formatPhoneNum(lead.phone_mobile)}</span>` : '';
                        htmlAppend += timeLine && lead.phone_work ? `<br>
                        <span class='calInfoIcon icon-mobile' style="color: ${textColor}"></span>
                        <span>${this.formatPhoneNum(lead.phone_work)}</span>` : '';
                        htmlAppend += `</div>`;
                    });
                }
            }

            htmlAppend += '</div>';
        }
        if (/* view.type !== "month" &&*/ exp.description) {
            htmlAppend += '<div class="hr-line-solid-no-margin" style="color: ' + textColor + '"></div>' +
            '<span class="eventDescriptionText" style="font-size: 0.9em; color: ' + textColor +
            '; padding-bottom: 6px; display: block;">' +
            exp.description + '</span></div>';
        }
        htmlAppend += '<div class="eventBottomPadding"></div>';

        element.querySelector('.fc-event-title')?.insertAdjacentHTML('afterend', htmlAppend);

        /** !!! Ocicko skryto, ale u nekterych zakazniku se jeste pouziva a u nekterzch budoucich se pouzivat bude, takze nemazat !!!  */
        // var adicon = $('<div />', {class: "icon-Eye", style: "width:15px;
        // height: 15px; float:right; position:relative; z-index:10; margin-right: -2px; margin-top:4px;"})
        //
        // adicon.click(function(ev) {
        //     ev.stopPropagation();
        //     $("#adicon_box").remove();
        //     self.calGetAdditionalInfo(ev, event);
        // });
        const calendarApi = this[this.state.calRef].current.getApi();

        if (calendarApi.getCurrentData().currentViewType === 'listACM' ||
            calendarApi.getCurrentData().currentViewType === 'listDayACM') {
            if (event.extendedProps.act_type === 'Meetings') {
                element.querySelector('a').insertAdjacentHTML('afterbegin', `<div class='calEventIconCircle'
                style='background-color: ${statusColor}; box-shadow: 0 0 12px ${statusColorShadow}'>
                <div class='calEventIcon agendaEvent icon-Meetings' /></div>`);
            } else if (event.extendedProps.act_type === 'Calls') {
                element.querySelector('a').insertAdjacentHTML('afterbegin', `<div class='calEventIconCircle'
                style='background-color: ${statusColor}; box-shadow: 0 0 12px ${statusColorShadow}'>
                <div class='calEventIcon agendaEvent icon-Calls' /></div>`);
            } else if (event.extendedProps.act_type === 'Tasks') {
                element.querySelector('a').insertAdjacentHTML('afterbegin', `<div class='calEventIconCircle'
                style='background-color: ${statusColor}; box-shadow: 0 0 12px ${statusColorShadow}'>
                <div class='calEventIcon agendaEvent icon-Tasks' /></div>`);
            }

            element.querySelector('a')?.insertAdjacentHTML('afterend', htmlAppend);

            let duration = moment.duration(event.end - event.start);
            duration = this.formatDuration(duration);
            if (timeLine) {
                if (this.weekNumbers) {
                    const evWeekNumber = moment(event.start).week();
                    if (evWeekNumber) {
                        timeLine.appendChild(document.createTextNode(' (' + evWeekNumber + ')'));
                    }
                }
                // insert span duration
                timeLine.appendChild(document.createElement('br'));
                timeLine.appendChild(document.createElement('span')).classList.add('calEventIcon', 'icon-time');
                timeLine.appendChild(document.createTextNode(duration));

                const countryCode = getCountryCallingCode(sAction.dataGet('conf/language').split('_')[1].toUpperCase());
                // insert span phone
                if (event.extendedProps.phone_c) {
                    const phoneC = formatPhoneNumberIntl(event.extendedProps.phone_c) ||
                    formatPhoneNumber(`+${countryCode}${event.extendedProps.phone_c}`) ||
                    event.extendedProps.mobile_c;
                    timeLine.appendChild(document.createElement('br'));
                    timeLine.appendChild(document.createElement('span')).classList.add('calEventIcon', 'icon-phone');
                    timeLine.appendChild(document.createTextNode(phoneC));
                }
                // insert span mobile
                if (event.extendedProps.mobile_c) {
                    const mobileC = formatPhoneNumberIntl(event.extendedProps.mobile_c) ||
                        formatPhoneNumber(`+${countryCode}${event.extendedProps.mobile_c}`) ||
                        event.extendedProps.mobile_c;
                    timeLine.appendChild(document.createElement('br'));
                    timeLine.appendChild(document.createElement('span')).classList.add('calEventIcon', 'icon-mobile');
                    timeLine.appendChild(document.createTextNode(mobileC));
                }
                // insert location
                if (event.extendedProps.location) {
                    timeLine.appendChild(document.createElement('br'));
                    timeLine.appendChild(document.createElement('span')).classList.add('calEventIcon', 'icon-location');
                    timeLine.appendChild(document.createElement('br'));
                    timeLine.appendChild(document.createTextNode(event.extendedProps.location));
                }
            }
        }

        const eventMainDiv = element.querySelector('.fc-event-main');

        // if event is smaller than 60px in week view add class compact event view
        if (!event.allDay && calendarApi.getCurrentData().currentViewType === 'agendaWeek' && eventMainDiv) {
            setTimeout(() => {
                const eventMainDivRc = eventMainDiv.getBoundingClientRect();
                if (eventMainDivRc.height < 60 && eventMainDivRc.height > 1) {
                    element.classList.add('compactEventView');
                }
                if (eventMainDivRc.width < 130 && eventMainDivRc.width > 1) {
                    element.classList.add('narrowCompactEventView');
                    if (eventMainDivRc.height < 90 && eventMainDivRc.height > 1) {
                        element.classList.add('shortCompactEventView');
                    }
                }
            }, 100);
        }

        if (!event.allDay && calendarApi.getCurrentData().currentViewType === 'agendaDay' && eventMainDiv) {
            setTimeout(() => {
                const eventMainDivRc = eventMainDiv.getBoundingClientRect();
                if (eventMainDivRc.height < 75 && eventMainDivRc.height > 1 && eventMainDiv.querySelector('.hr-line-solid-no-margin')) {
                    element.classList.add('compactEventDayView');
                }
            }, 100);
        }

        element.style.minHeight = '24px';
        if (view.type === 'agendaDay' && !event.allDay) {
            element.style.maxWidth = '450px';
        }
        element.classList.add('calNoBorder');
        // ziskat tridy podle stavu
        element.classList.add(sAction.calGetClassName(event.extendedProps.act_type, event.extendedProps.status));
    };

    handleDatesSet = (dateInfo) => {
        if (!this.state.ready) return; // jinak se tato funkce volala asi 3x při initu

        sAction.load();
        const getFrom = moment(dateInfo.start)
            .add(-1 * this.getMonthsBefore, 'months')
            .startOf('month')
            .add(-1 * this.getDaysBefore, 'days')
            .format('YYYY-MM-DD');

        const getTo = moment(dateInfo.end)
            .endOf('month')
            .add(this.getMonthsBefore, 'months')
            .add(this.getDaysAfter, 'days')
            .format('YYYY-MM-DD');

        if ((moment(this.state.loadedDates.from) < moment(dateInfo.end) && moment(this.state.loadedDates.from) > moment(dateInfo.start)) ||
            (moment(this.state.loadedDates.to) > moment(dateInfo.start) && moment(this.state.loadedDates.to) < moment(dateInfo.end))) {
            // out of loaded range
            this.setState({loaded: false});
        }

        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            this.calendarUpdateEvents(getFrom, getTo);
        }, 800);
    };

    handleDayClick = (events) => {
        if (!this.state.loaded) {
            console.warn('handleDayClick: calendar not loaded - wait until calendar is loaded');
            return;
        }
        if (!events) return;
        sAction.load();
        const date = moment(events.date);
        const params = {
            date: date,
            prefix: this.state.prefix,
        };

        if (events.view.type === this.defaultView) {
            const now = moment();
            date.set({
                hour: now.get('hour'),
                minute: now.get('minute'),
            });
        }

        this.setActivityInitData(sAction, params);
        sAction.unLoad();
        sAction.popup('calQuickCreate', {prefix: this.state.prefix});
    };

    callAgendaNewPopup = () => {
        sAction.unLoad();
        sAction.popup('calQuickCreate', {
            prefix: this.state.prefix,
        });
    };

    setExternalEvents = () => {
        const draggableEl = document?.getElementById('external-eventsNew');
        if (draggableEl) { // [INF] pokud jsme na jine zalozce nez kde ty external eventy, tak jsou external skryte
            new Draggable(draggableEl, {
                itemSelector: '.fc-event',
                eventData: function(eventEl) {
                    const eventData = sAction.fromJSON(eventEl.getAttribute('data-calendar'));

                    return {
                        title: eventData.title,
                        id: eventData.id,
                        extendedProps: eventData,
                    };
                },
            });

            // aby se eventy neoznacili jako draggable 2x, pak pri presunuti do calendare vyskocili dva popupy
            // kdyz probehne zmena, tak se prerenderuji a budou mit znovu .external-eventsNew
            draggableEl.id = 'external-events';
        }
    };
    changeView = (view) => {
        const way = this.props.way ? this.props.way + '/data/options' : 'calendar/setting'; // ?widgety:norm
        const calendarApi = this[this.state.calRef]?.current?.getApi();
        if (!calendarApi) return;
        const params = sAction.dataGet(way);
        const viewToSet = view ?? this.defaultView;
        calendarApi.changeView(viewToSet);
        calendarApi.scrollToTime(params.get('startIntervalHours') + ':' + params.get('startIntervalMinutes'));
        calendarApi.setOption('businessHours', {
            dow: [1, 2, 3, 4, 5], // daysOfWeek
            startTime: params.get('startIntervalHours') + ':' + params.get('startIntervalMinutes'), // start time
            endTime: params.get('endIntervalHours') + ':' + params.get('endIntervalMinutes'), // end time
        });
        calendarApi.setOption('firstDay', params.get('fdow') ?? 1);
        calendarApi.setOption('weekNumbers', params.get('weekNumbers'));
        calendarApi.setOption('weekText', sAction.translate('FC_WEEK_NUMBERS', 'Calendar'));
        calendarApi.setOption('allDayText', sAction.translate('FC_ALL_DAY', 'Calendar'));
        calendarApi.setOption('weekends', params.get('showWeekends'));
        this.weekNumbers = params.get('weekNumbers');

        if (view === 'agendaWeek' || view === 'agendaDay') {
            setTimeout(() => {
                const startTime = moment(`${params.get('startIntervalHours')}:${params.get('startIntervalMinutes')}:00`, 'HH:mm:ss');
                // scroll to start time - scrollTime
                calendarApi.scrollToTime(startTime.format('HH:mm:ss'));
            }, 1000);
        }

        sAction.dataSet('calendar/calViewType', viewToSet);
        this.setState({currentView: view});
    };

    // reschedCallBack() {
    // Zatim nemazat, nevime jestli u specif. zmen se nebude hodit
    // sAction.unLoad()
    // sAction.calGetEvents(true, (data) => {
    // this.setState({calendarEvents:data})// TODO proverit jestli neni potreba - mozna kvuli agende
    // });
    // }

    /**
     *
   * @param {class} self
   * @param {any} params
     */
    setActivityInitData(self, params) {
        const dateMeeting = params.date.clone();
        const start = params.date.format('YYYY-MM-DD HH:mm:ss');
        const end = params.date.add(15, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        const startMeeting = dateMeeting.format('YYYY-MM-DD HH:mm:ss');
        const endMeeting = dateMeeting.add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');

        self.dsClear();
        // priprava dat do popupu
        // automaticke pridavani prirazeneho uzivatele v predefinedFields v kalendari zruseno, kvuli sdilenym kalendarum
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/assigned_user_name/value', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/assigned_user_name/def/id_value', this.state.userID);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/relate/assigned_user_name', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/assigned_user_id', this.state.userID);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/date_start/value', startMeeting);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/date_start', startMeeting);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/date_end/value', endMeeting);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/date_end', endMeeting);
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/duration_hours/value', '0');
        sAction.dsAdd('set', this.state.prefix + '/meetingView/fields/duration_hours/def/minutesValue', '30');
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/duration_minutes', '30');
        sAction.dsAdd('set', this.state.prefix + '/meetingView/changes/fields/duration_hours', '0');
        if (this.useOrderLines) sAction.dsAdd('set', this.state.prefix + '/meetingView/orderLines', []);

        sAction.dsAdd('set', this.state.prefix + '/callView/fields/assigned_user_name/value', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/assigned_user_name/def/id_value', this.state.userID);
        sAction.dsAdd('set', this.state.prefix + '/callView/changes/fields/relate/assigned_user_name', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/callView/changes/fields/assigned_user_id', this.state.userID);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/date_start/value', start);
        sAction.dsAdd('set', this.state.prefix + '/callView/changes/fields/date_start', start);
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/duration_hours/value', '0');
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/duration_hours/def/minutesValue', '15');
        sAction.dsAdd('set', this.state.prefix + '/callView/fields/duration_minutes/value', '15');
        sAction.dsAdd('set', this.state.prefix + '/callView/changes/fields/duration_minutes', '15');
        sAction.dsAdd('set', this.state.prefix + '/callView/changes/fields/duration_hours', '0');

        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/assigned_user_name/value', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/assigned_user_name/def/id_value', this.state.userID);
        sAction.dsAdd('set', this.state.prefix + '/taskView/changes/fields/relate/assigned_user_name', this.props.userName);
        sAction.dsAdd('set', this.state.prefix + '/taskView/changes/fields/assigned_user_id', this.state.userID);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/date_start/value', start);
        sAction.dsAdd('set', this.state.prefix + '/taskView/fields/date_due/value', end);
        sAction.dsAdd('set', this.state.prefix + '/taskView/changes/fields/date_start', start);
        sAction.dsAdd('set', this.state.prefix + '/taskView/changes/fields/date_due', end);

        const action = {
            type: 'CALENDARINITACTIVITY',
            content: {
                invitees: {
                    Users: [],
                    Contacts: [],
                    Leads: [],
                },
            },
            prefix: params.prefix + '/invitees/relatedInv',
        };
        self.dsAddCustom(action);
        self.dsProcess();
    }

    /**
     * Changes weekend settings
     *
     * @param {class} calendarApi
     */
    changeWeekendSetting(calendarApi) {
        const apiWeekends = calendarApi?.getOption('weekends') ?? null;
        const propsWeekends = this.props?.data?.get('setting')?.get('showWeekends') ?? null;
        // change weekend settings, if it was changed by user
        if (apiWeekends != null && propsWeekends != null && propsWeekends !== apiWeekends) {
            calendarApi.setOption('weekends', propsWeekends); // this invoke another render!!!
        }
    }

    /**
     * Handles date picker
     *
     * @param {int|string|null} num
     * @returns {int|string}
     */
    formatPhoneNum(num) {
        const countryCode = getCountryCallingCode(sAction.dataGet('conf/language').split('_')[1].toUpperCase());
        if (!num) {
            return '';
        }
        const formatedNum = formatPhoneNumberIntl(num) ||
                    formatPhoneNumber(`+${countryCode}${num}`) ||
                    num;
        return formatedNum;
    }

    render() {
        const self = this;
        const reload = sAction.dataGet('calendar/reload');
        const updateEvent = sAction.dataGet('calendar/updateEvent');

        const gotoDate = sAction.dataGet('calendar/gotoDate');
        if (gotoDate) {
            this.datePickerHandle(gotoDate);
        }

        if (reload) {
            sAction.load();
            this.mapLoadedInit = {};
            this.mapLoaded = {};
        }

        // reload only if calendar ref exists
        if ((reload || this.state.rerender) && this[this.state.calRef].current) {
            sAction.load();
            this.calendarInit();
        }

        if (updateEvent) {
            const calendarApi = this[this.state.calRef]?.current?.getApi();
            const event = calendarApi.getEventById(updateEvent);
            event?.remove();
        }

        if (this.state.unpackActivities !== sAction.dataGet('calendar/setting/unpackActivities') && reload) {
            // neslo jinak aby se nezacyklilo tj nastavovane bez setState
            if (this.state.currentView === 'month') {
                this.state.calendarEvents = []; // eslint-disable-line
            }
            this.state.unpackActivities = !this.state.unpackActivities; // eslint-disable-line
        }

        if (this?.props?.data?.calViewType) {
            sAction.load();
            this.changeView(this.props?.data.calViewType);
        }
        const picker = <div className='hidden' id={'clickMeIfYouDare' + this.state.userID}><AcmDate
            autoOpen={this.state.openPicker}
            value={new Date()}
            onChange={(d) => this.datePickerHandle(d)}
        /></div>;

        // pro alternativni zobrazeni sdileneho kalendare
        // let height = this.props.viewsNumber?'calc((95vh - 150px) / ' + this.props.viewsNumber+')':'';
        let height = '100%';
        let overflow = 'auto';
        if (this.props.viewsNumber === 1) {
            height = this.props.viewsNumber ? 'calc((100dvh - 130px) / ' + this.props.viewsNumber + ')' : '';
            overflow = 'unset';
        }
        const language = sAction.dataGet('conf/language').slice(0, 2);
        const calendarApi = this[this.state.calRef]?.current?.getApi();
        this.changeWeekendSetting(calendarApi);
        const userName = this.props.userName;
        let rightButtons = 'datepicker';
        if (this.state.currentView === 'listACM' || this.state.currentView === 'listDayACM') {
            rightButtons += ' printAgenda';
        }

        return [
            <div className={'calBodyContainer' + (calendarApi?.view.type === 'agendaDay'?' sidewise':'') +
                (this.props.sharedView ? ' sharedView' : '')}
            style={{
                height: height,
                overflow: overflow,
            }}
            key={0}
            /* kvuli sdilenemu pohledu*/>

                {(userName && this.props.sharedView) && (
                    <div className={'fcCalendarTitle'}>{userName}</div>
                )}
                <div id={this.props.cssSelector}
                    className={' calBoard scrollUnifier ' + this.props.classBoard + ' ' + this.props.cssSelector}
                    // style={{ overflow: 'auto' }}
                >
                    {picker}

                    {!this.state.loaded &&
            <div className="lineLoaderContainer">
                <div className="lineLoader"></div>
            </div>
                    }

                    <FullCalendar
                        ref={this[this.state.calRef]}
                        locales={allLocales}
                        locale={language}
                        initialView={this.props?.data?.settings?.get('initView') ?? this.defaultView}
                        headerToolbar={{
                            left: 'today',
                            center: 'prev,title,next',
                            right: rightButtons,
                        }}
                        customButtons={{
                            datepicker: {
                                text: sAction.translate('LBL_DATE_PICKER', 'Calendar'),
                                click: function() {
                                    self.pickADate();
                                },
                            },
                            printAgenda: {
                                text: sAction.translate('LBL_PRINT_AGENDA', 'Calendar'),
                                click: function() {
                                    self.printAgenda();
                                },
                            },
                            userName: {
                                text: sAction.dataGet('calendar/settings/users/' + this.state.userID),
                            },
                            // ...other buttons
                        }}

                        buttonText={{
                            today: sAction.translate('LBL_TODAY_BTN', 'Calendar'),
                        }}
                        buttonIcons={{
                            datepicker: 'i icon-calendar-month calHeaderIcon',
                            printAgenda: 'i icon-print calHeaderIcon',
                        }}
                        views={{
                            dayGridMonth: {
                                displayEventTime: true,
                            },
                            month: { // kvuli starym instancim
                                type: 'dayGridMonth',
                                displayEventTime: true,
                            },
                            timeGridWeek: {
                                slotDuration: '00:15:00',
                                slotLabelInterval: '01:00',
                                displayEventTime: true,

                                // TODO jak upravit a pak i pro den... v locale to vyplnene je
                                // weekNumberFormat: { week:"short"},
                                // weekText: "T"
                            },
                            agendaWeek: { // kvuli starym instancim
                                type: 'timeGridWeek',
                                slotDuration: '00:15:00',
                                slotLabelInterval: '01:00',
                                displayEventTime: true,
                            },
                            timeGridDay: {
                                slotDuration: '00:15:00',
                                slotLabelInterval: '01:00',
                            },
                            agendaDay: { // kvuli starym instancim
                                type: 'timeGridDay',
                                slotDuration: '00:15:00',
                                slotLabelInterval: '01:00',
                                displayEventTime: true,
                            },
                            listACM: { // je potreba list plugin
                                displayEventTime: true,
                                weekNumbers: true,
                                type: 'listMonth',
                                duration: {days: 31},
                                buttonText: 'Agenda',
                                weekNumbersWithinDays: true,
                            },
                            listDayACM: { // je potreba list plugin
                                displayEventTime: true,
                                weekNumbers: true,
                                type: 'listMonth',
                                duration: {days: 1},
                                buttonText: 'Agenda Day',
                                weekNumbersWithinDays: true,
                            },
                        }}

                        editable={true}
                        allDaySlot={true}
                        eventStartEditable={true}
                        eventDurationEditable={true}
                        displayEventTime={true}
                        nowIndicator={true}
                        weekNumbers={true}
                        eventTextColor={'#2D3748'}
                        eventBorderColor={'#555555'}
                        // timeFormat={'H:mm'}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            omitZeroMinute: true,
                            // meridiem: 'short' // am / pm
                        }}
                        // minTime={"00:00:00"}
                        // maxTime={"24:00:00"}
                        // contentHeight={"auto"}
                        weekNumberFormat={{week: 'numeric'}}

                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}

                        events={this.state.calendarEvents ?? ''}

                        dateClick={this.handleDayClick}
                        eventDidMount={this.handleEventDidMount}
                        eventClick={this.handleEventClick}
                        eventResize={this.handleEventUpdate}// aktualizace pri roztahnuti eventu po kalendari
                        eventDrop={this.handleEventUpdate} // aktualizace pri presunu eventu po kalendari
                        eventReceive={this.handleEventReceive} // pokud pritahnu novy externi event
                        eventAdd={this.handleEventAdd} // pokud pritahnu novy externi event
                        datesSet={this.handleDatesSet} // pokud pritahnu novy externi event
                        eventMinHeight={27}
                        slotEventOverlap={false}
                    />
                </div>
            </div>,
            //
            ((this.state.currentView === 'listACM' && sAction.isMobile()) && (
                <div className={'viewActionPanelMobile'}>
                    <Button
                        onClick={this.callAgendaNewPopup}
                        className={''}
                        key={'new_record'}
                        id={'new_record'}
                    >
                        <div className={'icon-addIcon'}/>
                    </Button>
                </div>
            )),
        ];
    }
}

export default CalBody;

CalBody.propTypes = {
    data: PropTypes.object,
    user: PropTypes.object, // ale realne ho nestoane a funguje
    way: PropTypes.string,
    userID: PropTypes.string,
    userName: PropTypes.string,
    classBoard: PropTypes.string,
    cssSelector: PropTypes.string,
    viewsNumber: PropTypes.number,
    sharedView: PropTypes.bool,
    language: PropTypes.string,
};
