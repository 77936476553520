/**
 * reloadList - method for loading and reload listview
 * @param {int} offset
 * @param {int} page
 * @param {string} prefix
 * @param {any} savedSearch
 * @param {any} rowTotalCount
 * @param {any} previousList
 * @param {any} otherParams
 */
export default function reloadList(
    offset = 0,
    page = 1,
    prefix,
    savedSearch = null,
    rowTotalCount = null,
    previousList = null,
    otherParams = {},
) {
    this.load(); // aktvuje načítání
    let list = previousList;
    if (list === null) {
        list = this.dataGet(prefix); // získáme data z aktuálního listview
    }

    const module = list.modul;
    const searchData = this.reloadListParams({list, prefix, savedSearch, offset, page});

    // get saved search from url
    const urlParam = this.getUrlParams();
    if (!prefix.includes('subpanel') && !urlParam?.view) {
        this.setStorage('listViewSearch', {module: module, data: searchData});
    }
    // nacitame vsechny usery nehlede na "Nezobrazovat v seznamech" pokud se NEJEDNA o subpanel nebo popup
    if (prefix === 'view') {
        searchData.listview_type = 'list';
    } else if (prefix.includes('subpanel')) {
        searchData.listview_type = 'subpanel';
    } else if (prefix.includes('popup')) {
        searchData.listview_type = 'popup';
    }

    if (this.getViewName() !== 'import') {
        localStorage.setItem(module+'_saved_search_fail', true);
    }

    const timeout = setTimeout(function() {
        if (localStorage.getItem(module+'_saved_search_fail')) {
            localStorage.removeItem(module+'_saved_search');
        }
    }, 15000);

    searchData.viewType = this.isMobile() ? 'mobile' : 'desktop';

    const subpanelActivityFilter = this.getStorage('subpanelActivityFilter') || {[module]: {}};
    if (module === 'Activities' || module === 'History') {
        searchData.newActivityModules = subpanelActivityFilter;
    }

    this.rest.post('list/' + module, searchData, (data) => {
        clearTimeout(timeout);
        localStorage.removeItem(module+'_saved_search_fail');
        if (typeof data !== 'object' || data?.status === false) {
            localStorage.removeItem('listViewSearch');
            localStorage.removeItem('listFilterData');
            this.unLoad();
            this.error(this.translate(data?.errorMessage?.text ?? 'LBL_WRONG_RETURN_FROM_SERVER'));
            return;
        }

        // vypocty funkci sum/count/avg nad ciselnymi hodnotami
        if (data.fieldFunction) {
            this.handleFieldFunction(data, prefix);
            this.unLoad();
            return;
        }

        const content = this.listContent(data);
        if (data.order && data.order[0]) {
            content.orderBy = data.order[0].field;
            content.asc = data.order[0].sort.toLowerCase() === 'asc' ? 1 : 0;
        } else {
            content.orderBy = 'date_entered';
            content.asc = 0;
        }

        if (data['saved_search_id'] !== undefined && data['saved_search_id'] != null) {
            savedSearch = data['saved_search_id'];
        }

        let lastOrder = this.getStorage('lastOrder');
        if (lastOrder == null) {
            lastOrder = {};
        }
        lastOrder[module] = data.order;
        this.setStorage('lastOrder', lastOrder);

        const actFiltering = list.actFiltering;
        const defaultFilter = list.defaultFilter && list.defaultFilter.size > 0 ? list.defaultFilter : undefined;
        const akce = {
            type: 'RELOADLIST',
            listViewPrefix: prefix,
            view: this.getViewName(),
            page: module,
            filter: list.filter,
            buttons: data.menu,
            defaultFilter: defaultFilter,
            selected: list.selected,
            selectedActive: list.selectedActive,
            fieldFunction: list.fieldFunction,
            rowTotalCount: rowTotalCount,
            actPagination: page,
            savedSearch,
            content,
            actFiltering,
        };

        const view = this.getViewName();
        if (view === 'import') {
            akce.buttons = list.buttons;
        }

        const filterData = {
            filter: list.filter,
            actFiltering: actFiltering,
            actPagination: page,
            offset: content.offset,
            page: module,
            defaultFilter: defaultFilter,
        };

        const way = (list.type === 'subpanel')? `subpanel${this.dataGet('view/module')}${module}` : view + module;

        let filter = this.getStorage('listFilterData');
        // pokud uzivatel resizoval sloupec je potreba pridat widthInfo do struktury
        if (filter) {
            if (filter[way]) {
                filterData.widthInfo = filter[way].widthInfo;
            }
        }
        filter = filter === null ? {} : filter;
        // view+module kvuli importu - vyuziva list stejneho modulu kam importujeme, ale ma jine view
        filter[way] = filterData;
        this.setStorage('listFilterData', filter);

        if (list.type !== 'subpanel') {
            savedSearch = savedSearch ?? ' ';
            localStorage.setItem(module+'_saved_search', savedSearch);
        }

        // detect and prevent crash from fast clicking
        const viewFromStore = this.dataGet('conf/view');
        if (viewFromStore !== view) {
            console.warn('[Error] ReloadList - View name mismatch store:', viewFromStore, 'url:', view);
        }
        this.dsAdd('set', 'conf/view', view);

        this.dsAddCustom(akce);

        if (list.type === 'popup') {
            this.dsAdd('set', prefix + '/customData/useDefaultFilter', list.customData.get('useDefaultFilter'));
        }

        this.dsProcess();

        // provolani metody subpanel() v _custom/...
        if (list.type === 'subpanel' && otherParams.subpanelAction === 'new_relate') {
            const module = defaultFilter.get('subpanel')?.module;
            if (module) {
                this.custom('subpanel', {module, subpanelData: content});
            }
        }

        /*
      Pokud se jedná o uložené vyhledávíní je potřeba provolat funkci afterLoadListView, kvůli tomu aby
      se správně zobrazovalo listview
    */
        if (savedSearch != null) {
            this.afterLoadListView(prefix);
        }
    // _________________________________________________________________________________________________
    }, false);
}
